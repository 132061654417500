
import axios from "axios";
export const axiosInterCeptors = axios.create();
axiosInterCeptors.interceptors.request.use(
  (req: any) => {
    req.headers = {
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);