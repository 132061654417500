import Lottie from "react-lottie-player";
import Loading from "../../assets/gifs/doted.json"

const LoadingDotted = () => {
  return (
    <>
      <Lottie
        loop
        animationData={Loading}
        play
        style={{ width: 70, height: 70 }}
      />
    </>
  );
};

export default LoadingDotted;
