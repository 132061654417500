import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import StarrySky from "../../components/starBackground/star";

const Contact = () => {
  const form = useRef<any>();

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        form.current,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="productsContainer">
      <StarrySky />
      <div className="productsPageContainer">
        <div className="titlePageScrollProducts">#CONTACT US</div>
        <div className="contactUscontainer">
          <div className="contactUsPart1">
            <div className="contactPart1Title">Contact Now</div>
            <div className="contactPart1Des">
              <div className="contactPart1Des">Hey</div>
              <div className="contactPart1Des">Let's Talk</div>
            </div>
            <div className="contactPart1Email">info@gienah.tech</div>
          </div>
          <div className="lineContactUs">
            <div className="linec"></div>
            <div className="lineText">Or</div>
            <div className="linec"></div>
          </div>
          <div className="contactUsPart2">
            <div className="emailContainer">
              <form ref={form} onSubmit={sendEmail}>
                <input
                  placeholder="Name"
                  autoComplete="off"
                  type="text"
                  name="user_name"
                />
                <input
                  placeholder="Email"
                  autoComplete="off"
                  type="email"
                  name="user_email"
                />
                <textarea placeholder="Message" name="message" />
                <input className="submitEmail" type="submit" value="Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
