import React, { useState, useEffect, useRef } from "react";
import { code, consultant, design, growth } from "../../assets/icons";
import StarrySky from "../../components/starBackground/star";
import useMousePos from "../../hooks/useMousPos";

const Services = () => {
  return (
    <div className="servicesContainer">
      <StarrySky />
      <Test />
    </div>
  );
};

const Test = () => {
  const relative1 = useRef<any>();
  const relative2 = useRef<any>();
  const relative3 = useRef<any>();
  const relative4 = useRef<any>();

  const mousePos = useMousePos();

  const [mouseHover, setMouseHover] = useState(-1);

  return (
    <div className="servicesPageContainer ">
      <div className="titlePageScroll">#SERVICES</div>
      <div className="servicesCardContainer">
        <div className="rightSectionServices">
          <div
            ref={relative1}
            onMouseLeave={() => {
              setMouseHover(-1);
            }}
            onMouseEnter={(e) => {
              setMouseHover(1);
            }}
            className="cardServicesRelative"
          >
            <div className="cardServices">
              <div className="cardServicesTop">
                <div className="titleCardServices">Consultancy</div>
                <img className="cardServicesIcon" src={consultant} />
              </div>
              <div className="descriptionCardServices">
                <div className="itemDescriptionServices">
                  Turn idea into product (Rfp)
                </div>
                <div className="itemDescriptionServices">
                  Define business plan and business model
                </div>
                <div className="itemDescriptionServices">
                  Go to market strategy
                </div>
              </div>
            </div>
            <div
              className="backdropColor"
              style={
                mouseHover === 1
                  ? {
                      top: 0,
                      left: 0,
                      transform: `translate(${
                        mousePos.x -
                        relative1.current?.getBoundingClientRect().left -
                        50
                      }px,${
                        mousePos.y -
                        relative1.current?.getBoundingClientRect().top -
                        50
                      }px)`,
                    }
                  : {}
              }
            ></div>
          </div>
          <div
            ref={relative2}
            onMouseLeave={() => {
              setMouseHover(-1);
            }}
            onMouseEnter={(e) => {
              setMouseHover(2);
            }}
            className="cardServicesRelative"
          >
            <div className="cardServices">
              <div className="cardServicesTop">
                <div className="titleCardServices">Design</div>
                <img className="cardServicesIcon" src={design} />
              </div>
              <div className="descriptionCardServices">
                <div className="itemDescriptionServices">User flow design</div>
                <div className="itemDescriptionServices">
                  ui/ux design and auditing
                </div>
                <div className="itemDescriptionServices">
                  Landing page design
                </div>
              </div>
            </div>
            <div
              className="backdropColor2"
              style={
                mouseHover === 2
                  ? {
                      top: 0,
                      left: 0,
                      transform: `translate(${
                        mousePos.x -
                        relative2.current?.getBoundingClientRect().left -
                        50
                      }px,${
                        mousePos.y -
                        relative2.current?.getBoundingClientRect().top -
                        50
                      }px)`,
                    }
                  : {}
              }
            ></div>
          </div>
        </div>
        {/* <div className="servicesBorderMapContainer">
            <div className="circleMapServices"></div>
            <div className="servicesBorderMap"></div>
            <div className="circleMapServices"></div>
            <div className="servicesBorderMap1"></div>
            <div className="circleMapServices"></div>
            <div className="servicesBorderMap"></div>
            <div className="circleMapServices"></div>
          </div> */}
        <div className="leftSectionServices">
          <div
            ref={relative3}
            onMouseLeave={() => {
              setMouseHover(-1);
            }}
            onMouseEnter={(e) => {
              setMouseHover(3);
            }}
            className="cardServicesRelative"
          >
            <div className="cardServices">
              <div className="cardServicesTop">
                <div className="titleCardServices">Development</div>
                <img className="cardServicesIcon" src={code} />
              </div>
              <div className="descriptionCardServices">
                <div className="itemDescriptionServices">MVP development</div>
                <div className="itemDescriptionServices">CMS Development</div>
                <div className="itemDescriptionServices">
                  Node js development
                </div>
              </div>
            </div>
            <div
              style={
                mouseHover === 3
                  ? {
                      top: 0,
                      left: 0,
                      transform: `translate(${
                        mousePos.x -
                        relative3.current?.getBoundingClientRect().left -
                        50
                      }px,${
                        mousePos.y -
                        relative3.current?.getBoundingClientRect().top -
                        50
                      }px)`,
                    }
                  : {}
              }
              className="backdropColor1"
            ></div>
          </div>
          <div
            ref={relative4}
            onMouseLeave={() => {
              setMouseHover(-1);
            }}
            onMouseEnter={(e) => {
              setMouseHover(4);
            }}
            className="cardServicesRelative"
          >
            <div className="cardServices">
              <div className="cardServicesTop">
                <div className="titleCardServices">Growth</div>
                <img className="cardServicesIcon" src={growth} />
              </div>
              <div className="descriptionCardServices">
                <div className="itemDescriptionServices">Digital marketing</div>
                <div className="itemDescriptionServices">
                  Search engine optimization
                </div>
                <div className="itemDescriptionServices">Content marketing</div>
              </div>
            </div>
            <div
              style={
                mouseHover === 4
                  ? {
                      top: 0,
                      left: 0,
                      transform: `translate(${
                        mousePos.x -
                        relative4.current?.getBoundingClientRect().left -
                        50
                      }px,${
                        mousePos.y -
                        relative4.current?.getBoundingClientRect().top -
                        50
                      }px)`,
                    }
                  : {}
              }
              className="backdropColor3"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
