import React , { useState , useLayoutEffect} from "react";
import {
  office1,
  office2,
  office3,
  office6,
} from "../assets/images";
import Navbar from "../layouts/componentsLayout/Navbar";
import NavbarMobile from "../layouts/componentsLayout/NavbarMobile";
import StarrySky from "./starBackground/star";

const AboutUs = () => {
  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);
  return (
    <div className="productsContainer">
      <StarrySky />
      <div className="aboutPageContainer scrollbar-div">
        {width > breakpoint ? (
          <Navbar  />
        ) : (
          <NavbarMobile />
        )}
        <div className="titlePageHire">#ABOUT US</div>
        <div className="aboutUsContainer">
          <div className="aboutUsImgContainer">
            <div className="aboutUsImgPart1">
              <div className="sectionImg2">
                <img src={office2} />
                <img src={office3} />
              </div>
              <div className="sectionImg">
                <img src={office1} />
              </div>
              <div className="sectionImg3">
                <img src={office6} />
              </div>
            </div>
          </div>
          <div className="aboutUsTextContainer">
            <div className="aboutUsTextItem">
              <div className="aboutUsTextTitle">We drive transformation</div>
              <div className="aboutUsTextDescription">
                in our clients’ businesses by embracing a bold spirit of
                innovation with an unwavering commitment. Our multidisciplinary
                team implement a dynamic formula of an unmatched combination of
                perspectives, technology, data, operational know-how to deliver
                capabilities across sectors globally for a more sustainable
                tomorrow.
              </div>
            </div>
            <div className="aboutUsTextItem">
              <div className="aboutUsTextTitle">Our values</div>
              <div className="aboutUsTextDescription">
                We believe in demonstrating a strong commitment to sustainable,
                responsible, honest and transparent business practices. Our
                highly collaborative and innovative approach turns ideas into
                viable businesses, helping out clients achieve rich competitive
                edge and fluency.
              </div>
            </div>
            <div className="aboutUsTextItem">
              <div className="aboutUsTextTitle">Our team</div>
              <div className="aboutUsTextDescription">
                We tried to gather talented people from different regions to
                solve complex problems and make new opportunity for our
                customers. Also we try to find blue ocean for your business that
                could make you pioneer in your industry
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
