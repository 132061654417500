import React, { useLayoutEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { UpRight, UpRightA } from "../assets/icons";
import Navbar from "../layouts/componentsLayout/Navbar";
import NavbarMobile from "../layouts/componentsLayout/NavbarMobile";
import { showAllCategories, showAllJob } from "../services/api/api";
import StarrySky from "./starBackground/star";

const Hire = () => {
  const [jobData , setJobData] = useState();
  const navigate = useNavigate();
  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);

  const { data } = useQuery("allJob", showAllJob);
  const { data: dataCategories } = useQuery("allCategories", showAllCategories);

  const shortDescription: any = (str: any) => {
    return str.length > 100 ? str.substring(0, 100) + "..." : str;
  };

  const goApply = (item:any) => {
   
    navigate("/hire/Apply", { state: item });
  };
  return (
    <div className="productsContainer">
      <StarrySky />
      <div className="productsPageContainer">
        {width > breakpoint ? <Navbar /> : <NavbarMobile />}
        <div className="titlePageHire">#JOB OPPORTUNITIES</div>
        <div className="hirePageContainer ">
          <div className="hireContainer">
            {data?.map((item: any) => (
              <div className="hireCardContainer">
                <div className="hireCardTopsSection">
                  <div className="hireCardTitle">{item.title}</div>
                  <div className="hireCardBtn pointer" onClick={() => goApply(item)}>
                    <span>Apply</span>
                    <img alt="icon" src={UpRightA} />
                  </div>
                </div>
                <div className="hireCardDescription">
                  {shortDescription(item.description)}
                </div>
                <div className="hireCardAddres">
                  <div className="hireCardAddresText">{item.address}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hire;
