import React, { useLayoutEffect, useState , useEffect } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Auth from "./Auth";
import Services from "../home/Services";
import ProductsFirst from "../home/ProductsFirst";
import ProductsSec from "../home/ProductsSec";
import ProductsList from "../home/ProductsList";
import Agile from "../home/Agile";
import Contact from "../home/Contact";
import AgileMobile from "../home/AgileMobile";



const HomePage = () => {
  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);

  return (
    <ReactFullpage

      anchors={["intro", "services", "products", "agile", "contact-us"]}
      scrollingSpeed={1000}
      navigation={true}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section ">
              <Auth fullpageApi={fullpageApi} />
            </div>
            <div className="section">
              <Services />
            </div>
            <div className="section">
              <div className="slide" data-anchor="tops">
                <ProductsFirst fullpageApi={fullpageApi} />
              </div>
              <div className="slide" data-anchor="airshow">
                <ProductsSec fullpageApi={fullpageApi} />
              </div>
              <div className="slide" data-anchor="list">
                <ProductsList fullpageApi={fullpageApi} />
              </div>
            </div>
            <div className="section">
             <Agile /> 
            </div>
            <div className="section">
              <Contact />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default HomePage;
