import { useCallback, useLayoutEffect, useState } from "react";
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import ParticlesBackground from "../../components/particlesB/ParticlesBackground";
import Navbar from "../../layouts/componentsLayout/Navbar";
import { TypeAnimation } from "react-type-animation";
import NavbarMobile from "../../layouts/componentsLayout/NavbarMobile";

const Auth = ({ fullpageApi }: any) => {
  const particlesInit = useCallback(async (engine: Engine) => {
    console.log(engine);

    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container);
    },
    []
  );

  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);

  return (
    <div className="authContainer ">
      <ParticlesBackground />
      <div className="authContent">
        {width > breakpoint ? (
          <Navbar fullpageApi={fullpageApi} />
        ) : (
          <NavbarMobile fullpageApi={fullpageApi} />
        )}
        <div className="titleAuth">
          <div className="textTitle ">
            <div className="containerTextTitle ">
              <div className="containerTextTitleItem">Where imagination meets technology;</div>
              <div className="containerTextTitleItem">Creating digital experiences beyond your expectations.</div>
              <span>
                <TypeAnimation
                  // Same String at the start will only be typed once, initially
                  sequence={[
                    " Product Definition",
                    2000,
                    "Design",
                    2000,
                    "Development",
                    2000,
                    "and Growth",
                    2000,
                  ]}
                  speed={20}
                  style={{ fontSize: "3.3rem" }}
                  wrapper="span"
                  repeat={Infinity}
                />
              </span>
            </div>
          </div>
          <div className="titlePart2">
            <div className="descriptionTitle">
              Ready to start your digital transformation?
            </div>
            <div
              onClick={() => {
                fullpageApi.moveTo("contact-us", 4);
              }}
              className="buttonTitle pointer"
            >
              LET'S GO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
