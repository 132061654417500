import { ISendForm } from "../../types/typesApi";
import { axiosInterCeptors } from "../config/axiosInterceptor";
import { axiosInterceptorFormData } from "../config/axiosInterceptorFormData";
import { baseUrl } from "../config/urls";

export const showAllJob = async () => {
  const result = await axiosInterCeptors.get(`${baseUrl}/career`);
  if (result) {
    return result.data;
  }
};

export const showAllCategories = async () => {
    const result = await axiosInterCeptors.get(`${baseUrl}/categories`);
    if (result) {
      return result.data;
    }
};


export const apply = async (data: ISendForm) => {
  const result = await axiosInterceptorFormData.post(
    `${baseUrl}/career`,
    {
      full_name: data.full_name,
      email: data.email,
      message: data.message,
      career_id: data.career_id,
      file: data.file,
    }
  );
  if (result) {
    return result.data;
  }
};