import React from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../assets/images";

const Navbar = ({ fullpageApi }: any) => {
  const navigate = useNavigate();

  const goHire = () => {
    window.location.href = "/hire";
  };
  const goAboutUs = () => {
    window.location.href = "/about-us";
  };
  return (
    <div className="navbarContainer">
      <div className="navbarposisson">
        <div className="navbaritemContainer">
          <div className="navbarItemPart1">
            <div
              className="navbaritem pointer"
              onClick={() => {
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("services", 1)
                  : navigate("/#services");
              }}
            >
              Services
            </div>
            <div
              className="navbaritem pointer"
              onClick={() => {
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("products", 2)
                  : navigate("/#products");
              }}
            >
              Products
            </div>
            <div
              className="navbaritem pointer"
              onClick={() => {
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("agile", 3)
                  : navigate("/#agile");
              }}
            >
              Agile Methodology
            </div>
          </div>
          <div
            className="logoNavbar pointer"
            onClick={() => navigate("/#intro")}
          >
            <img src={Logo} />
          </div>
          <div className="navbarItemPart1">
            <div
              className="navbaritem pointer"
              onClick={() => {
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("contact-us", 4)
                  : navigate("/#contact-us");
              }}
            >
              Contact Us
            </div>
            <div onClick={goHire} className="navbaritem pointer">
              Job Opportunities
            </div>
            <div onClick={goAboutUs} className="navbaritem pointer">
              About Us
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
