import React, { useState } from "react";
import {
  arrow,
  code,
  deploy,
  design,
  strategy,
  testing,
} from "../../assets/icons";

import StarrySky from "../../components/starBackground/star";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Agile = () => {
  return (
    <div className="productsContainer">
      <StarrySky />
      <AgileMe />
    </div>
  );
};

const AgileMe = () => {
  const [changeColor, setChangeColor] = useState(-1);
  return (
    <div className="productsPageContainer">
      <div className="titlePageScrollProducts">#AGILE METHODOLOGY</div>
      <div className="agileContainer">
        <div className="agileShapeContainer">
          <div className="agileShape">
            <div
              onMouseLeave={() => {
                setChangeColor(-1);
              }}
              onMouseEnter={(e) => {
                setChangeColor(1);
              }}
              className="agileRelative"
              id="strategy"
            >
              <div className="agileCircle">
                <img src={strategy} />
              </div>
              <div className="agileFake"></div>
              <div className="backdropColorAgile"></div>
            </div>
            <div className="agileText">Strategy</div>
            <ReactTooltip   anchorId="strategy">
              <div
                className="tooltipContainer"
                style={{
                  border: "2px solid #ff00dd50",
                  boxShadow: "0 0 8px 1px #ff00dd5e ",
                }}
              >
                <div>Initial Conversation</div>
                <div>Requirement Analysis</div>
                <div>Define Scope</div>
              </div>
            </ReactTooltip>
          </div>
          <div className="agileShape">
            <div
              className="agileRelative"
              id="design"
              onMouseLeave={() => {
                setChangeColor(-1);
              }}
              onMouseEnter={(e) => {
                setChangeColor(2);
              }}
            >
              <div className="agileCircle">
                <img src={design} />
              </div>
              <div className="agileFake"></div>
              <div
                className="backdropColorAgile"
                style={{
                  background: "#00f7ff48",
                  boxShadow: "0 0 50px 40px #00f7ff50 ",
                }}
              ></div>
            </div>
            <div className="agileText">Design</div>
            <ReactTooltip anchorId="design">
              <div
                className="tooltipContainer"
                style={{
                  border: "2px solid #00f7ff48",
                  boxShadow: "0 0 8px 1px #00f7ff50 ",
                }}
              >
                <div>User Flows</div>
                <div>Wireframes</div>
                <div>Brand Integration</div>
                <div>Mockups</div>
                <div>Prototypes</div>
              </div>
            </ReactTooltip>
          </div>
          <div className="agileShape">
            <div
              className="agileRelative"
              id="development"
              onMouseLeave={() => {
                setChangeColor(-1);
              }}
              onMouseEnter={(e) => {
                setChangeColor(3);
              }}
            >
              <div className="agileCircle">
                <img src={code} />
              </div>
              <div className="agileFake"></div>
              <div
                className="backdropColorAgile"
                style={{
                  background: "#e5ff0048",
                  boxShadow: "0 0 50px 40px #e5ff0050 ",
                }}
              ></div>
            </div>
            <ReactTooltip anchorId="development">
              <div
                className="tooltipContainer"
                style={{
                  border: "2px solid #e5ff0048",
                  boxShadow: "0 0 8px 1px #e5ff0050 ",
                }}
              >
                <div>Architecture</div>
                <div>Coding</div>
                <div>DB Design</div>
                <div>API</div>
                <div>Weekly Sprints</div>
              </div>
            </ReactTooltip>
            <div className="agileText">Development</div>
          </div>
          <div className="agileShape">
            <div
              className="agileRelative"
              id="testing"
              onMouseLeave={() => {
                setChangeColor(-1);
              }}
              onMouseEnter={(e) => {
                setChangeColor(4);
              }}
            >
              <div className="agileCircle">
                <img src={testing} />
              </div>
              <div className="agileFake"></div>
              <div
                className="backdropColorAgile"
                style={{
                  background: "#a200ff48",
                  boxShadow: "0 0 50px 40px #a200ff50 ",
                }}
              ></div>
            </div>
            <ReactTooltip anchorId="testing">
              <div
                className="tooltipContainer"
                style={{
                  border: "2px solid #a200ff48",
                  boxShadow: "0 0 8px 1px #a200ff50 ",
                }}
              >
                <div>Regression Testing</div>
                <div>Load Testing</div>
                <div>Device Testing</div>
                <div>Usability Testing</div>
                <div>Reporting</div>
              </div>
            </ReactTooltip>
            <div className="agileText">Testing</div>
          </div>
          <div className="agileShape">
            <div
              className="agileRelative"
              id="deployment"
              onMouseLeave={() => {
                setChangeColor(-1);
              }}
              onMouseEnter={(e) => {
                setChangeColor(5);
              }}
            >
              <div className="agileCircle">
                <img src={deploy} />
              </div>
              <div className="agileFake"></div>
              <div
                className="backdropColorAgile"
                style={{
                  background: "#00ff0448",
                  boxShadow: "0 0 50px 40px #00ff0450 ",
                }}
              ></div>
            </div>
            <ReactTooltip anchorId="deployment">
              <div
                className="tooltipContainer"
                style={{
                  border: "2px solid #00ff0448",
                  boxShadow: "0 0 8px 1px #00ff0448 ",
                }}
              >
                <div>Deployment Plan</div>
                <div>Release to Production</div>
              </div>
            </ReactTooltip>
            <div className="agileText">Deployment</div>
          </div>
        </div>
        <div className="agileRoadMap">
          <div className="agileArrowItem">
            <div
              className={
                changeColor === 1 ? "agileLineArrowActive1" : "agileLineArrow"
              }
            ></div>
            <div className="agileArrow">
              <img src={arrow} />
            </div>
          </div>
          <div className="agileArrowItem">
            <div className="agileArrow">
              <img className="arrowRotate" src={arrow} />
            </div>
            <div
              className={
                changeColor === 2
                  ? "agileLineArrowActive2"
                  : changeColor === 3
                  ? "agileLineArrowActive3"
                  : "agileLineArrow"
              }
            ></div>
            <div className="agileArrow">
              <img src={arrow} />
            </div>
          </div>
          <div className="agileArrowItem">
            <div className="agileArrow">
              <img className="arrowRotate" src={arrow} />
            </div>
            <div
              className={
                changeColor === 4
                  ? "agileLineArrowActive4"
                  : changeColor === 3
                  ? "agileLineArrowActive3"
                  : "agileLineArrow"
              }
            ></div>
            <div className="agileArrow">
              <img src={arrow} />
            </div>
          </div>
          <div className="agileArrowItem">
            <div
              className={
                changeColor === 5 ? "agileLineArrowActive5" : "agileLineArrow"
              }
            ></div>
            <div className="agileArrow">
              <img src={arrow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agile;
