import axios from "axios";

export const axiosInterceptorFormData = axios.create();

axiosInterceptorFormData.interceptors.request.use(
  (req: any) => {
    req.headers = {
      Authorization: localStorage.getItem("token")
        ? `Bearer ${localStorage.getItem("token")}`
        : "",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    };
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosInterceptorFormData.interceptors.response.use(
  (res: any) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
