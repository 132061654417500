import React, { useLayoutEffect, useState } from "react";
import { scroll } from "../../assets/icons";
import { tops1 } from "../../assets/images";
import StarrySky from "../../components/starBackground/star";

const ProductsFirst = ({ fullpageApi }: any) => {
  const moveWebsite = () => {
    window.open("https://topsapp.net/");
  };
  const moveDownload = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=net.topsapp.app"
    );
  };

  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);
  return (
    <div
      onWheel={(e) => {
        if (e.deltaY > 0) {
          fullpageApi.moveSlideRight();
        }
      }}
      className="productsContainer"
    >
      <StarrySky />
      <div className="productsPageContainer">
        <div className="productsPageContainer">
          <div className="titlePageScrollProducts">#PRODUCTS</div>
          <div className="topProductsParent">
            <div className="productsListContainer">
              <div className="topProductsContainer">
                <div className="topProductsText">
                  <div className="topProductsPart1">
                    <div className="topProductsTitle">Tops</div>
                    <div className="topProductsDescription">
                      Tops Game is a feature rich educational game for high
                      school students. That is a fully complex project and high
                      tech service that include ai and microservices technology.
                      This platform could tolerate more than 5M users. It can
                      connect different students with same characteristics to
                      each other then evaluate them. Also tops has a uniqe Ai
                      engine that could recommend the best course for each pupil
                      by his data.
                    </div>
                  </div>
                  <div className="linkButtonContainer">
                    <div className="linkButton pointer" onClick={moveWebsite}>
                      Website
                    </div>
                    <div className="linkButton pointer" onClick={moveDownload}>
                      Download
                    </div>
                  </div>
                </div>
                <div className="topProductsImg">
                  <img src={tops1} />
                </div>
                {width > breakpoint ? null : (
                  <div className="scrolliconContainer">
                    <img className="scrollicon" src={scroll} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsFirst;
