import "./assets/css/home/home.css";
import "./assets/css/main/main.css";

import "react-tooltip/dist/react-tooltip.css";

import Router from "./router/Router";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
