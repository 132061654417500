import React, { useEffect } from "react";

import Drawer from "react-modern-drawer";

import "react-modern-drawer/dist/index.css";
import { useLocation, useNavigate, useHref } from "react-router-dom";
import { menuIcon } from "../../assets/icons";
import { Logo } from "../../assets/images";

const NavbarMobile = ({ fullpageApi }: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    const elem: any = document.getElementById("fp-nav");
    if (!elem) return;
    const style: any = elem?.style;
    if (isOpen === true) {
      style.display = "none";
    } else {
      style.display = "block";
    }
  }, [isOpen]);

  const goHire = () => {
    window.location.href = "/hire";
    toggleDrawer();
  };
  const goAboutUs = () => {
    window.location.href = "/about-us";
    toggleDrawer();
  };

  const navigate = useNavigate();

  return (
    <div className="navbarContainerMobile">
      <div className="navbarCloseContainer">
        <div className="navbarMobileIcon">
          <img src={Logo} onClick={() => navigate("/#intro")} />
        </div>
        <div className="buttonShowNavbarItem" onClick={toggleDrawer}>
          <img src={menuIcon} />
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        className="containerNvabarItem"
        overlayOpacity={1}
        overlayColor={"#00000090"}
      >
        <div className="containerNvabarItemIn">
          <div className="navbarItemMobileSection">
            <div
              className="navbarItemMobile"
              onClick={() => {
                toggleDrawer();
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("services", 1)
                  : navigate("/#services");
              }}
            >
              Services
            </div>
            <div
              className="navbarItemMobile"
              onClick={() => {
                toggleDrawer();
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("products", 2)
                  : navigate("/#products");
              }}
            >
              Products
            </div>
            <div
              className="navbarItemMobile"
              onClick={() => {
                toggleDrawer();
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("agile", 3)
                  : navigate("/#agile");
              }}
            >
              Agile Methodology
            </div>
            <div
              className="navbarItemMobile"
              onClick={() => {
                toggleDrawer();
                window.location.pathname === "/"
                  ? fullpageApi.moveTo("contact-us", 4)
                  : navigate("/#contact-us");
              }}
            >
              Contact Us
            </div>
            <div className="navbarItemMobile" onClick={goHire}>
              Job Opportunities
            </div>
            <div className="navbarItemMobile" onClick={goAboutUs}>
              About Us
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NavbarMobile;


