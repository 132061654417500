import React, { useLayoutEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { File } from "../assets/icons";
import Navbar from "../layouts/componentsLayout/Navbar";
import NavbarMobile from "../layouts/componentsLayout/NavbarMobile";
import StarrySky from "./starBackground/star";
import { useMutation } from "react-query";
import { apply } from "../services/api/api";
import LoadingDotted from "./loading/LoadingDotted";

const HireApply = () => {
  return (
    <div className="productsContainer">
      <StarrySky />
      <HireApplyDeep />
    </div>
  );
};

const HireApplyDeep = () => {
  const location = useLocation();
  console.log(location.state);
  const [srcData, setSrcData] = useState<any>("");
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [error, setError] = useState<boolean>(false);

  const changeName = (nem: string) => {
    setName(nem);
  };

  const changeEmail = (em: string) => {
    setEmail(em);
  };

  const changePhone = (ph: string) => {
    setPhone(ph);
  };
  console.log(email);

  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);
  const hiddenFileInput = useRef<any>();
  const chooseFile = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileChoose = (event: any) => {
    if (event?.target?.files) {
      const src = event.target.files[0];
      setSrcData(src);
    }
  };

  const { mutate, isLoading } = useMutation("applyForm", apply, {
    onSuccess: () => {},
  });

  const validation = (value: any) => {
    if (value) {
      return { text: "", isError: false };
    } else {
      return { text: "This field is required.", isError: true };
    }
  };

  const send = () => {
    const checkName = validation(name);
    const checkEmail = validation(email);
    const checkPhone = validation(phone);
    const checkFile = validation(srcData);

    console.log(checkName);
    if (
      !checkName.isError &&
      !checkEmail.isError &&
      !checkPhone.isError &&
      !checkFile.isError
    ) {
      const obj = {
        full_name: name,
        email: email,
        message: phone,
        career_id: location.state.id,
        file: srcData,
      };

      mutate(obj);
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className="productsPageContainer">
        {width > breakpoint ? <Navbar /> : <NavbarMobile />}
        <div className="titlePageHire">{location.state.title}</div>
        <div className="hirePageContainer ">
          <div className="hireContainer">
            <div className="hireApplyDescription">
              <div className="hireApplyDescriptionTitle">Job Description :</div>
              <div className="hireApplyDescriptionText">
                {location.state.description}
              </div>
            </div>
            <div className="hireApplyDescription">
              <div className="hireApplyDescriptionTitle">Requirement : </div>
              <div className="hireApplyDescriptionText">
                {location.state.requirement}
              </div>
            </div>
            <div className="hireApplyForm">
              <div className="hireApplyDescriptionTitle">
                Personal Information :
              </div>
              <div className="hireApplyFormLeftSection">
                <div className="hireApplyFormInput">
                  <div className="hireApplyFormInputTitle">
                    <div className="hireApplyFormInputTitleText">Full Name</div>
                    {error ? (
                      <div className="hireApplyFormInputTitleTextValidation">
                        {validation(name).isError
                          ? `${validation(name).text}`
                          : null}
                      </div>
                    ) : null}
                  </div>
                  <input
                    className="hireApplyFormInputDeep"
                    placeholder="Enter your Full Name"
                    onChange={(e) => changeName(e.target.value)}
                  />
                </div>
                <div className="hireApplyFormInput">
                  <div className="hireApplyFormInputTitle">
                    <div className="hireApplyFormInputTitleText">
                      Email Address
                    </div>
                    {error ? (
                      <div className="hireApplyFormInputTitleTextValidation">
                        {validation(email).isError
                          ? `${validation(email).text}`
                          : null}
                      </div>
                    ) : null}
                  </div>
                  <input
                    className="hireApplyFormInputDeep"
                    placeholder="Enter your Email Address"
                    onChange={(e) => changeEmail(e.target.value)}
                  />
                </div>
                <div className="hireApplyFormInput">
                  <div className="hireApplyFormInputTitle">
                    <div className="hireApplyFormInputTitleText">
                      Phone Number
                    </div>
                    {error ? (
                      <div className="hireApplyFormInputTitleTextValidation">
                        {validation(phone).isError
                          ? `${validation(phone).text}`
                          : null}
                      </div>
                    ) : null}
                  </div>
                  <input
                    className="hireApplyFormInputDeep"
                    placeholder="Enter your  Phone Number"
                    onChange={(e) => changePhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="hireApplyFormFile">
                <div
                  className="hireApplyFormFileSelect pointer"
                  onClick={chooseFile}
                >
                  <img className="fileSelectIcon" alt="file" src={File} />
                  {srcData && (
                    <div className="hireApplyTextInput">{srcData?.name}</div>
                  )}
                  <div className="learningAddBtn">
                    <>
                      <input
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                        type={"file"}
                        accept=".PDF, .DOC, .DOCX , .JPG , .PNG"
                        onChange={handleFileChoose}
                      />
                    </>
                  </div>
                </div>
                <div className="hireApplyFormInputTitleMessage">
                  Upload your CV or resume file
                  {error ? (
                    <div className="hireApplyFormInputTitleTextValidation">
                      {validation(srcData).isError
                        ? `${validation(srcData).text}`
                        : null}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="submitApply ">
                <div className="submitApplyText pointer" onClick={send}>
                  {isLoading ? <LoadingDotted /> : "Send"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HireApply;
