import React, { useLayoutEffect, useState } from "react";
import { scroll } from "../../assets/icons";
import { airshow1 } from "../../assets/images";
import StarrySky from "../../components/starBackground/star";

const ProductsSec = ({ fullpageApi }: any) => {
  const moveWebsite = () => {
    window.open("https://app.iranairshow.app/");
  };
  const breakpoint: any = 1200;
  const [width, setWidth] = useState(window.innerWidth);
  const viewportWidth = () => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  };
  useLayoutEffect(() => {
    viewportWidth();
  }, []);
  return (
    <div
      onWheel={(e) => {
        if (e.deltaY < 0) {
          fullpageApi.moveSlideLeft();
        } else {
          fullpageApi.moveSlideRight();
        }
      }}
      className="productsContainer"
    >
      <StarrySky />
      <div className="productsPageContainer">
        <div className="productsPageContainer">
          <div className="titlePageScrollProducts">#PRODUCTS</div>
          <div className="topProductsParent">
            <div className="productsListContainer">
              <div className="topProductsContainer">
                <div className="topProductsText">
                  <div className="topProductsPart1">
                    <div className="topProductsPart1">
                      <div className="topProductsTitle">
                         Airshow 2022
                      </div>
                      <div className="topProductsDescription">
                        This application allows you to find out about the latest
                        news and events. Also, it is possible to register
                        business cards, brochures, catalogs, posters, etc. at
                        each stand separately. Companies will be able to use the
                        live broadcast platform for their customers. By using
                        this application, visitors will have the opportunity to
                        actively participate in the exhibition.
                      </div>
                    </div>
                  </div>
                  <div className="linkButtonContainer">
                    <div className="linkButton pointer" onClick={moveWebsite}>
                      Website
                    </div>
                  </div>
                </div>
                <div className="topProductsImg">
                  <img src={airshow1} />
                </div>
                {width > breakpoint ? null : (
                  <div className="scrolliconContainer">
                    <img className="scrollicon" src={scroll} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSec;
