import React, { useEffect } from "react";
import StarrySky from "./starBackground/star";

const DetailProduct = () => {
  const data: any = localStorage.getItem("data");
  const item = JSON.parse(data);
  console.log(item);
  return (
    <div className="productsDContainer scrollbar-div">
      <StarrySky />
      <div className="productDetailPageContainer ">
        <div className="bannerPtoducts">
          <img src={item.banner} /> 
        </div>
        <div className="detailProductContent">
          <div className="detatilProductsTextContainer">
            <div className="detailProductsTitle">{item.title}</div>
            <div className="detailProductsInformation">
              <div className="detailProductsleftSection">
                <div className="detailProductsleftSectionItem">
                  <div className="detailProductsleftSectionItemTitle">
                    Category
                  </div>
                  <span>{item.category}</span>
                </div>
                <div className="detailProductsleftSectionItem">
                  <div className="detailProductsleftSectionItemTitle">Year</div>
                  <span>{item.year}</span>
                </div>
                <div className="detailProductsleftSectionItem">
                  <div className="detailProductsleftSectionItemTitle">
                    Technology
                  </div>
                  <span>{item.technology}</span>
                </div>
              </div>
              <div className="detailProductsRightSection">
                {item.description}
              </div>
            </div>
            <div className="uiShowContainer">
              {item.ui?.map((img: any) => (
                <div className="uiShowImg">
                  <img alt="ui" src={img} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailProduct;
