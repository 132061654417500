import { ComponentType, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AboutUs from "../components/AboutUs";
import DetailProduct from "../components/DetailProduct";
import Hire from "../components/Hire";
import HireApply from "../components/HireApply";
import HomePage from "../pages/auth/HomePage";
const AuthPage: ComponentType = lazy(() => import("../pages/auth/Auth"));

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/hire" element={<Hire />} />
      <Route path="/hire/Apply" element={<HireApply />} />
      <Route path="/product/:id" element={<DetailProduct />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
};

export default Router;
